import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { Helmet } from 'react-helmet';
import Layout from '../../layout';
import Footer from '../../components/Footer/index';
import CalculatorHeading from '../../components/Calculators/Heading';

const calculators = () => {
  const intl = useIntl();
  return (
    <Layout>
      <Helmet title={intl.formatMessage({ id: 'title' })} />
      <CalculatorHeading />
      <Footer />
    </Layout>
  );
};

export default calculators;

import React from 'react';
import { Link } from 'gatsby-plugin-intl';

function CalculatorHeading() {
  return (
    <>
      <div
        className="masthead masthead-blog"
        style={{ position: 'relative', height: '70vh' }}
      >
        <div className="masthead-contents">
          <div className="masthead-text">
            <h1 className="small">
              Free <strong>Calculators</strong>
            </h1>
            <p className="sub-lead">
              For the purpose of general use, danfebooks team has develop these
              calculators you can use free of charge without having submit your
              data across internet.
            </p>
            <p className="sub-lead" style={{ margin: '5rem 0' }}>
              {/* <Link
                to="/calculators/incometax"
                className="masthead-buttons masthead-button-appreciate"
              >
                Income Tax Calculator
              </Link> */}

              <Link
                to="/calculators/emi"
                className="masthead-buttons masthead-button-appreciate"
              >
                EMI Calculator
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default CalculatorHeading;
